<script>
import {
    ArrowUpIcon,
    MailIcon,
    PhoneIcon,
    YoutubeIcon,
    MapPinIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    GitlabIcon
} from 'vue-feather-icons';
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
/**
 * Helpcenter-overview component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Footer,
        ArrowUpIcon,
        MailIcon,
        PhoneIcon,
        MapPinIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        GithubIcon,
        YoutubeIcon,
        GitlabIcon
    }
}
</script>

<template>
<div>
    <Navbar :nav-light="true" />

    <!-- Hero Start -->


    <section
   class="bg-half d-table w-100 powerup bg-primary ">

  <div class='light x1'></div>
  <div class='light x2'></div>
  <div class='light x3'></div>
  <div class='light x4'></div>
  <div class='light x5'></div>
  <div class='light x6'></div>
  <div class='light x7'></div>
  <div class='light x8'></div>
  <div class='light x9'></div>

  <div class="bg-overlay " style="margin-bottom: -80px; background: url('/images/real/build2.png') center bottom no-repeat;"></div>




        <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">



                      

                      
                        <h4 class="title text-white mt-20">How can we support you? </h4>



                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item active" aria-current="page"> <i class="mdi mdi-code-greater-than"></i> Support</li>
                                     <li class="breadcrumb-item"><router-link to="/knowledgebase"> <i class="mdi mdi-code-greater-than-or-equal"></i> Knowledgebase</router-link></li>
                                     <li class="breadcrumb-item"><router-link to="/estimate"> <i class="mdi mdi-clipboard-text"></i> Estimate</router-link></li>

                                   
                                </ul>
                            </nav>
                        </div>



                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->




    </section>
    <!--end section-->





    <!-- Start Section -->
    <section class="section">




    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->











        <div class="container mt-lg-0">
            <div class="row mt-40 align-items-center mb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
                      <h6 class="my-color-1"> Greetings,</h6>
              <h4 class="title mb-4">Find the support you need</h4>

                        <p class="text-muted mb-0 para-desc"><span class="text-muted">Karukaj DIGITAL </span> offer multiple ways for fast problem resolution and live assistance</p>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-center text-md-right">


                      <a v-b-tooltip.hover title="if you are a new customer." target="_blank" href="https://besiness.karukaj.com.bd/forms/quote/45e759339042747f47df23db4d26987c" class="btn btn-primary text-center">SIGN UP NOW <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>



            
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

        </div>
        <!--end container-->







           
        











      <div class="container mt-100  mb-4 pb-4 mt-60">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <div class="media features feature-clean">
              <div class="icons my-color-1 text-center mx-auto">
                <i class=" uil-folder-medical d-block rounded h3 mb-0"></i>
              </div>
              <div class="content ml-3">
                <h5 class="mb-1">
                  <a href="javascript:void(0)" class="text-dark">Helpdesk Ticket</a>
                </h5>
                <p class="text-muted mb-0">
                 For troubleshooting of your most complex issues, we'll suggest to open a support ticket with details.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="media features feature-clean">
              <div class="icons my-color-1 text-center mx-auto">


                <i class="uil uil-whatsapp d-block rounded h3 mb-0"></i>
              </div>
              <div class="content ml-3">
                <h5 class="mb-1">
                  <a href="javascript:void(0)" class="text-dark">Live chat</a>
                </h5>
                <p class="text-muted mb-0">
                  Available 24/7, our live chat system or WhatsApp live Chat is really fast and efficient in real-time.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
            <div class="media features feature-clean">
              <div class="icons my-color-1 text-center mx-auto">
                <i class="uil-incoming-call d-block rounded h3 mb-0"></i>
              </div>
              <div class="content ml-3">
                <h5 class="mb-1">
                  <a href="javascript:void(0)" class="text-dark">Phone support</a>
                </h5>
                <p class="text-muted mb-0">
                  For issues that can be best addressed over the phone, we provide you an option to call our experienced operator
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
            <div class="media features feature-clean">
              <div class="icons my-color-1 text-center mx-auto">
                <i class="uil uil-bag d-block rounded h3 mb-0"></i>
              </div>
              <div class="content ml-3">
                <h5 class="mb-1">
                  <a href="javascript:void(0)" class="text-dark">Suggestions</a>
                </h5>
                <p class="text-muted mb-0">
                  
Our blog may already have an answer to your question, or you may have no idea what we mentioned.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!--end row-->
      </div>
      <!--end container-->

















      
      <div class="container mt-100 mt-60">
            <div class="row">
              
              <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="media align-items-center shadow rounded p-4 features">
                        <div class="icons m-0 rounded h2 my-color-1 text-center px-3">
                            <i class=" uil-calculator-alt"></i>
                        </div>
                        <div class="content ml-4">
                            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Estimate Form</a></h5>
                            <p class="text-muted mb-0">No login is required, if you are a new customer. 
                             </p>
                            <div class="mt-2">

                              <router-link  class="btn btn-sm btn-soft-primary" to="/estimate"  v-b-tooltip.hover title="Submit an estimate form and book an appointment"   >Estimate Form</router-link>


                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-6 col-md-6 col-12">
                    <div class="media align-items-center shadow rounded p-4 features">
                  
                            
                        <div class="icons m-0 rounded h2 my-color-1 text-center px-3">
                            <i class="uil-folder-question"></i>
                        </div>
                        <div class="content ml-4">
                            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Helpdesk Ticket</a></h5>
                            <p class="text-muted mb-0">For existing members and account login is required.</p>
                            <div class="mt-2">
                                <a title="Chat on Facebook Massenger" target="_blank" href="https://besiness.karukaj.com.bd/forms/quote/45e759339042747f47df23db4d26987c" class="btn btn-sm btn-soft-primary">Login for Support</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

            </div>
            <!--end row-->
        </div>
        <!--end container-->












        <div class="container mt-60">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6 col-12">


            <div class="title-heading mt-5 pt-4">


                <span class="my-color-1 font-weight-bold">Since we are active on our website</span>
                        <h1 class="heading">We're here to support you</h1>
                        

        
                        <p class="text-muted ">
                          
                          
                          
                          
                          <span class="text-muted">Karukaj DIGITAL</span> friendly chat representatives successfully resolve 90%+ of the inquiries   <span class="underline"  v-b-tooltip.hover title="
                          
                          
                          It is important to confirm us quickly to prioritize the work.
                          
                          "  >one after one</span>. 
                          Usually, we reply within 1 day on average and in the majority of the cases the issues get resolved with a single reply.
                          <br>     <br>
                          We pursue relationships based on transparency, persistence, mutual trust, and integrity with our employees, customers and other business partners. But, sharing contact information like
                           email addresses, phone numbers, etc.is against our 
                           
                           
                           <router-link  class="my-color-1 underline" to="/terms"  v-b-tooltip.hover title="Terms and conditions of contract and acceptance."   >Terms of Service</router-link>
                           
                           
                           and thereby causing annoyance to others. All contact must be done through 
                           "<router-link  class="my-color-1 underline" to="/estimate"  v-b-tooltip.hover title="Submit an estimate form and book an appointment."   >Estimate Form</router-link>" until a contract begins and open a 
                           "<a v-b-tooltip.hover title="For members support and maintenance purposes only." target="_blank" href="https://besiness.karukaj.com.bd/forms/quote/45e759339042747f47df23db4d26987c" class="underline my-color-1">Helpdesk Ticket</a>" 
                           for maintenance.

                     
                        
                        </p>




                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <map-pin-icon class="fea icon-m-md text-dark mr-3"></map-pin-icon>
                            </div>
                            <div class="media-body content ">
                                 <h5 class="title font-weight-bold mb-0">Our Location</h5>

                                 7th Floor, Ka 215/B, JS Tower, Merul Badda, Gulshan, Dhaka, BD. <br>

                                <a href="javascript: void(0);" class="video-play-icon text-muted underline " v-b-modal.modal-1>View on Google map</a>
                            </div>
                            <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-1" size="lg" centered body-class="p-0">
                                <iframe height="500" width="800" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4342.122730387992!2d90.41996321855859!3d23.773204123512432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1643032484214!5m2!1sen!2sbd" style="border:0"></iframe>
                            </b-modal>
                        </div>

                        <br>

                        <p class="text-muted font-weight-bold ">Stay connected on Social Media. Here you don't have to wait for reply. </p>






                        <ul class="list-unstyled social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a target="_blank" href="https://www.facebook.com/karukaj.digital" class="rounded">
                                    <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://www.instagram.com/karukaj.digital" class="rounded">
                                    <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://www.twitter.com/karukaj_digital" class="rounded">
                                    <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://www.linkedin.com/company/karukajdigital/" class="rounded">
                                    <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://karukaj.github.io/" class="rounded">
                                    <github-icon class="fea icon-sm fea-social"></github-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://www.youtube.com/karukaj" class="rounded">
                                    <youtube-icon class="fea icon-sm fea-social"></youtube-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://www.pinterest.com/karukaj_digital" class="rounded">
                                    <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>



                </div>







                <div class="col-lg-5 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="card rounded border-0 shadow-lg ml-lg-5">
                        <div class="card-body">
                            <div class="text-center">


                              <p class="text-s text-center my-color-2 font-weight-bold underline">REQUEST A PHONE CALL / POKE US</p>

                                <div class="section-title">
                                  <div class="shape-before">
              <div class="carousel-cell">
                <img
                  src="images/clipart/support.png"
                  class="img-fluid mover"
                  alt=""
                />
              </div>

            </div>    
                                      





                                </div>
                            </div>

                            <div class="content">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-12">


                                          <iframe width="100%" height="260" src="https://business.karukaj.com.bd/forms/wtl/d6f2b65fbe46c754b666f9bf50024373" frameborder="0" sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin" allowfullscreen></iframe>
                                       
                                       
                                       
                                        </div>
                                        <!--end col-->

                                        <div class="card-body">
<span class="text-s">
    <i class="mdi mdi-file-findmdi mdi-debug-step-over"></i>  <u>Please Note </u> : If there is too much work pressure it may not be possible to make a request call or receive a call. It's better if  
    

    
      fill out an 
    
    "<router-link   target="_blank" class="text-muted underline " to="/estimate">estimate form</router-link>" and 
    
    
    <a target="_blank" href="https://business.karukaj.com.bd/authentication/register" class="underline text-muted ">Sign Up</a>.
  </span>


</div>



                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end container-->







    </section>
    <!--end section-->
    <!-- End Section -->


        <div class="container mt-4 mt-lg-0">
            <div class="row align-items-center mb-4 pb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
              <h4 class="title mb-4 font-weight-bold">Need Maintenance Support?</h4>

  <p class="text-muted mb-0 para-desc">If you are our member then you will definitely find information here. Login with your <b>provided email</b> or reset using 
    
    


    <a v-b-tooltip.hover title="Just type your email address and check the email inbox." target="_blank" href="https://business.karukaj.com.bd/authentication/forgot_password" class="  underline my-color-1">Forgot Password</a>
     or 
    <a v-b-tooltip.hover title="Confirm a registration with business and project details." target="_blank" href="https://business.karukaj.com.bd/authentication/register" class="  underline my-color-1">Sign Up</a>.

  
</p>

                 


</div>
 </div>
<!--end col-->

 <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-center text-md-right">
                     <br>










                        <a v-b-tooltip.hover title="Login with your provided email and password or reset." target="_blank" href="https://business.karukaj.com.bd/clients/open_ticket" class="btn btn-soft-primary font-weight-bold "> <i class="mdi mdi-file-find"></i>  Open A Ticket<arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

        </div>
        <!--end container-->





  
  




    
    <section
      class="section mt-100 bg-white pt-0 mt-4 pt-2"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build2.png') +
          ') bottom no-repeat',
      }"
    >
  

      
    <div class="container">

   
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
       
              <div class="mt-4 pt-2">
                 <h4 class="mb-4 font-weight-bold">Have questions about digital marketing?</h4>




                 <router-link  class="btn btn-primary font-weight-bold text-center" to="/knowledgebase"  v-b-tooltip.hover title="A half kilometer knowledge-based questions & answers."   ><i class="mdi mdi-lightbulb-outline"></i>   Read Knowledgebase<i class="mdi mdi-chevron-right"></i> </router-link> 

                
                 
<br><br>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->


        
<div class=" mt-100 row justify-content-center">
  <div class="col-12 text-center">

      <div class="mt-60 pt-2">
                    



    </div>
  </div>
  <!--end col-->
</div>
<!--end row-->
</div>
<!--end container-->



      <!--end container-->
    </section>
    <!--end section-->







    <!-- End -->
<div class="position-relative">
        <div class="shape overflow-hidden text-footer">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#4e4e4e"></path>
            </svg>
        </div>
    </div>
    <!--end section-->





  
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-primary btn-icon back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>


</template>
